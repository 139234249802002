
import {Corp} from '../charts/Interfaces.tsx'
import {allocateSugg,downloadSVGs} from '../charts/util.tsx';
import React, { useEffect, useState, useRef } from 'react';
// import * as _ from 'lodash'
import { DebounceInput } from 'react-debounce-input';
import { normalizeName, client } from './util.tsx';


interface Match{
    'userInput':string,
    'sugg':Corp[]
  }

interface SearchBarProps{
    matchFunc: (match:Corp[])=>void,
    focusFunc: (sugg:Corp[])=>void
}

export default function SearchBar(props:SearchBarProps){
    // const client = new Meilisearch(meiliConfig)
    const lastMatchRef = useRef<Match>()

    const onChange = async (e:React.ChangeEvent<HTMLInputElement>) => {
        const userInput = e.target.value;
        // console.log(userInput)
        const len = userInput.length
        let match: Corp[] = []
        const indices = ['A_stocks','H_stocks','M_stocks']
        const queries = indices.map(function(x){
          return {
            indexUid: x,
            q: userInput,
            limit: 10,
          }
        })
        if(len>0){
          const res = await client.multiSearch({queries:queries})
          
          const allocation = allocateSugg(res.results)
          // console.log(res.results.map(x=>x.hits.length))
          // console.log(allocation)
          match = res.results.reduce((prev,x)=>{
            const hits = x.hits.slice(0,allocation[x.indexUid]) as Corp[]
            return prev.concat(hits)
          },[] as Corp[])
          match.forEach(normalizeName)
        }
        
        lastMatchRef.current = {'userInput':userInput,'sugg':match}
        props.matchFunc(match)
      }

    const onSearchFocus = (e)=>{
        const userInput = e.target.value;
        if(userInput && lastMatchRef.current?.userInput){
            if(userInput === lastMatchRef.current.userInput){
            props.focusFunc(lastMatchRef.current.sugg)
            // setSuggestions(lastMatchRef.current.sugg)
            }
        }
    }

    return <DebounceInput
    minLength={1}
    debounceTimeout={100}
    placeholder='Search a stock'
    onChange={event => onChange(event)}
    onFocus={e=>onSearchFocus(e)} />

}