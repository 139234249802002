import React, { useEffect, useState, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';

import {Corp,
  GraphData, GraphTypes
} from '../charts/Interfaces.tsx'


import BarYear from '../charts/BarYear/BarYear.tsx';
import * as _ from 'lodash'
import Trend from '../charts/Trend.tsx'
import CashFlows from '../charts/CashFlows.tsx';

import SearchBar from '../comps/SearchBar.tsx';
import { normalizeName, client } from '../comps/util.tsx';

// TODO:
// click on banner to select activeCorp
// ? add title to chart numbers
// 

const getGraphId = (data:GraphData) =>
  data.type === 'barYear' ? data.corp.code+data.type+data.year : 
    data.corp.code+data.type

// const client = new Meilisearch(meiliConfig)

  interface Match{
    'userInput':string,
    'sugg':Corp[]
  }

interface SearchProps{
  token:string
  clearToken:()=>void
}

export default function Search(propsx:{}){
  const [token, clearToken]: [string,()=>void] = useOutletContext();
  const props: SearchProps = {
    token: token,
    clearToken: clearToken
  }
  // let data = []
  // console.log('new render')
  const [graphs, setGraphs] = useState<GraphData[]>([])
  // const [data, setData] = useState<Corp[]>([])
  // const [dataLoaded, setDataLoaded] = useState(false)
  const [suggestions, setSuggestions] = useState<Corp[]>([])
  // const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)

  const [year,setYear] = useState('latest')
  const date = new Date()
  const currYear = date.getFullYear()
  const choiceYear = date.getMonth() >= 4 ? currYear:currYear-1
  const years = ['latest'].concat(_.range(6).map(i=>`${choiceYear-i-1}`))

  // const activeCorpRef = useRef<Corp>()
  const [activeCorp,setActiveCorp] = useState<Corp>()
  const latestYearMap = useRef<Map<string,string>>(new Map())
  // const graphTypeRef = useRef<'trend'|'barYear'>('barYear')
  const [graphType, setGraphType] = useState<GraphTypes>('barYear')

  const suggRef = useRef<Corp[]>()

  const lastMatchRef = useRef<Match>()


  const defaultCodes = ['SH600519','SH601336','SH600036','SH600009',
    '00700','09988','AAPL','SZ300750']
  const getSugg = ()=>{
    return suggRef.current!
  }


  const modifyGraphs = (corp:Corp,year:string,localGraphType?:GraphTypes) =>{
    if(_.isUndefined(localGraphType))
      localGraphType = graphType
    // console.log(corp,localGraphType)
    const mapYear = (code:string,year:string)=> 
      year === 'latest' && latestYearMap.current.has(code) ? 
      latestYearMap.current.get(corp.code):year

    let matched: GraphData[]
    if(localGraphType === 'barYear')
      matched = graphs.filter(x=>x.type === 'barYear' && x.corp.code===corp.code && 
        mapYear(x.corp.code,x.year!) === mapYear(corp.code,year))
    else{
      matched = graphs.filter(x=>x.type === localGraphType && x.corp.code === corp.code)
    }
    if(matched.length === 0){
      const graph:GraphData = localGraphType === 'barYear' ? 
        {corp:corp,type:localGraphType,year:year}: 
        {corp:corp,type:localGraphType}
      graphs.push(graph)
      // setCorps([corps]) won't work because reactjs
      // determine the equalily of arrays by checking if they
      // refer to the same array object. Even if the same array
      // has element change, React still thinks it as equal.
      // So we need to create a new array.
      setGraphs([...graphs])
    }
    // return matched.length === 0
  }

  const onClick = (corp:Corp) => {
    //TODO how to set input value to '' dynamically?
    setSuggestions([])
    setActiveCorp(corp)
    // console.log('in click',corps)
    modifyGraphs(corp,year)
  }

  const onYearChange = (e: React.ChangeEvent<HTMLSelectElement>)=>{
    const year = e.target.value
    setYear(year)
    if(activeCorp){
      modifyGraphs(activeCorp,year)
    }
  }

  const getCorps = async(codes:string[])=>{
    const indices = ['A_stocks','H_stocks','M_stocks']
    const getQueries = (input:string) =>{
        return indices.map(function(x){
          return {
            indexUid: x,
            q: input,
            limit: 1,
          }
        })
    }
    const promises = codes.map(code=>
      client.multiSearch({queries:getQueries(code)}))
    const res = await Promise.all(promises)
    // console.log(res)
    const mp = {}
    res.forEach(x=>{
      x.results.forEach(x=>{
        x.hits.forEach(x=>{
          const y = x as Corp
          normalizeName(y)
          mp[x.code] = y
        })
      })
    })
    return codes.map(x=>mp[x]) as Corp[]
  }


  const clickIndustry = async (tag:string) => {
    let codes:string[]=[];
    switch (tag){
      case 'snack':
        codes = ['SZ002557','SH603719','SZ002847','SZ300783']
        break;
      case 'phone':
        codes = ['AAPL','01810','SH688036']
        break;
      case 'airport':
        codes = ['SH600009','SH600004','SZ000089']
        break;
      case 'steel':
        codes = ['SH600019',"SH601003",'SH600569',
                  'SH600010','SZ000825','SH601005']
        break;
    }
    // console.log(tag,codes)
    const newCorps = await getCorps(codes)
    setSuggestions([])
    setGraphs(newCorps.map(x=>({corp:x,type:'barYear',year:year})))
  }

  const items = suggestions.map((s)=>
    <li key={s.code}
        onClick={() => onClick(s)}>
      <a className="dropdown-item" href="#">
        {s.name}   {s.market}
      </a>
    </li>
  )
  
  
  const clickCorpDel = (i:number)=>{
    // console.log(graphs)
    const new_graphs = graphs.filter((x,j)=>j!==i)
    setGraphs([...new_graphs])

    if(activeCorp){
      const matchCorps = new_graphs.filter(x=>x.corp.code === 
        activeCorp.code)
      if(matchCorps.length === 0 && new_graphs.length > 0)
        setActiveCorp(new_graphs[new_graphs.length-1].corp)
    }

    if(new_graphs.length === 0){
      setSuggestions(getSugg())
      setActiveCorp(undefined)
    }
  }

  const getBannerText = (barYear:GraphData)=>{
    if(barYear.type === 'barYear'){
      const text = barYear.year === 'latest' ?
        '':` ${barYear.year}`
      return text
    }else{
      return ''
    }
  }

  const bannerClick = (item:GraphData)=>{
    // const item_copy = structuredClone(item)
    // if(_.has(item_copy,'year')){
    //   delete (item_copy as any).year
    // }
    setActiveCorp(item.corp)
  }
  const corp_items = graphs.map((barYear,i)=>
    <div key={getGraphId(barYear)}
      onClick={()=>bannerClick(barYear)}
      className={"corp-selected "+(
      activeCorp?.code === barYear.corp.code? 
      'corp-selected-active':'')}
      >
      <span>{barYear.corp.name}   {barYear.corp.market} 
      {getBannerText(barYear)}</span>
      <button onClick={()=>clickCorpDel(i)}
       type="button" className="btn-close corp-selected-close" 
       aria-label="Close"></button>
    </div>
    // <li key={corp.code}>
    //   <span>{corp.name}   {corp.market}</span>
    //   <span onClick={(e)=>clickCorpDel(corp,e)}>     Del</span>      
    // </li>
  )

  const chooseGraph = (graphData:GraphData,i:number) =>{
    switch(graphData.type){
      case 'barYear':
        return <BarYear code={graphData.corp.code} year={graphData.year!}
        lastestYearMap={latestYearMap} token={props.token}
        clearToken={props.clearToken} rmSelf={()=>clickCorpDel(i)}/>
      case 'trend':
        return   <Trend code={graphData.corp.code}></Trend>
      case 'cashFlows':
        return <CashFlows code={graphData.corp.code}
        token={props.token}  clearToken={props.clearToken}
        rmSelf={()=>clickCorpDel(i)}></CashFlows>
    }
  }

  const graphsJsx = graphs.map((graphData,i) =>{
    // console.log(graphData,i)
    return <div className='Chart' key={getGraphId(graphData)}>
      { chooseGraph(graphData,i) } </div>}
  )

  // const normalizeName = (x:Corp)=>{
  //   if(x.market === 'A' || (x.market === 'H' && x.sep)){
  //     x.name = _.replace(x.name,/ /g,'')
  //   }
  // }
  // const onChange = async (e:React.ChangeEvent<HTMLInputElement>) => {
  //   const userInput = e.target.value;
  //   // console.log(userInput)
  //   const len = userInput.length
  //   let match: Corp[] = []
  //   const indices = ['A_stocks','H_stocks','M_stocks']
  //   const queries = indices.map(function(x){
  //     return {
  //       indexUid: x,
  //       q: userInput,
  //       limit: 10,
  //     }
  //   })
  //   if(len>0){
  //     const res = await client.multiSearch({queries:queries})
      
  //     const allocation = allocateSugg(res.results)
  //     // console.log(res.results.map(x=>x.hits.length))
  //     // console.log(allocation)
  //     match = res.results.reduce((prev,x)=>{
  //       const hits = x.hits.slice(0,allocation[x.indexUid]) as Corp[]
  //       return prev.concat(hits)
  //     },[] as Corp[])
  //     match.forEach(normalizeName)
  //   }
    
  //   lastMatchRef.current = {'userInput':userInput,'sugg':match}
  //   if(match.length > 0)
  //     setSuggestions(match)
  //   else if(graphs.length === 0)
  //     setSuggestions(getSugg())
  //   else
  //     setSuggestions([])

  //   // setValue(userInput)
  // }

  const matchFunc = (match:Corp[])=>{
    if(match.length > 0)
      setSuggestions(match)
    else if(graphs.length === 0)
      setSuggestions(getSugg())
    else
      setSuggestions([])
  }
  // console.log(suggestions)
  // console.log(items)
  // const onSearchFocus = (e)=>{
  //   const userInput = e.target.value;
  //   if(userInput && lastMatchRef.current?.userInput){
  //     if(userInput === lastMatchRef.current.userInput){
  //       setSuggestions(lastMatchRef.current.sugg)
  //     }
  //   }
      
  // }

  const focusFunc = (rec:Corp[])=>{
    setSuggestions(rec)
  }

  const onGraphTypeChange = (e:React.FormEvent<HTMLSpanElement>)=>{
    const graphType = (e.target as HTMLInputElement).value as 'barYear'|'trend'
    setGraphType(graphType)
    if(activeCorp)
      modifyGraphs(activeCorp,year,graphType)
  }

  
  useEffect(() => {
    async function load_suggestions(){
      suggRef.current = await getCorps(defaultCodes)
      // suggRef.current = defaultCodes.map(x=>mp[x]) as Corp[]
      setSuggestions(suggRef.current)
      // const names_url = `${url}/names`
      // const fetchedData = await fetch(names_url).then(res => res.json())
      // setData(fetchedData)
      // // setDataLoaded(true)
      // // data = fetchedData
      // // console.log(data)
      // // console.log('b')
      // // console.log('in effect')
      // setSuggestions(getSugg(fetchedData))
      setLoading(false)
    }
    setLoading(true)
    load_suggestions()

    
    // console.log('abc')
    // client.index('stocks').search('meta').then((res) => console.log(res))
  },[])



  return <div>
  <div className='container'>
    <div className='row'>
      <div className='col'>
        <SearchBar matchFunc={matchFunc} 
          focusFunc={focusFunc} />
        {/* <DebounceInput
          minLength={1}
          debounceTimeout={100}
          placeholder='Search a stock'
          onChange={event => onChange(event)}
          onFocus={e=>onSearchFocus(e)} /> */}
        {/* <input onChange={(e) => onChange(e)}/> */}
      </div>
      <div className='col'>
        <span onChange={onGraphTypeChange}>
          <input type='radio' name='graphType' value='barYear' defaultChecked></input>BarYear &nbsp;
          <input type='radio' name='graphType' value='trend'></input>Trend &nbsp;
          <input type='radio' name='graphType' value='cashFlows'></input>CashFlows
        </span>
        {/* <select value={graphTypeRef.current}
          onChange={e=>{graphTypeRef.current=
            e.target.value as 'barYear'|'trend'}}>
          <option key='barYear'>barYear</option>
          <option key='trend'>trend</option>
        </select> */}
      </div>
      <div className='col'>
        <select value={year}
          onChange={onYearChange}
          disabled={graphType==='trend'}>
          {years.map(year=><option key={year}>{year}</option>)}
        </select>
      </div>
      {/* <div className='col'>
        <button onClick={()=>downloadSVGs()}>Download</button>
      </div> */}
      <div className='col'>
        <span>行业内比较：</span> 
        <a className='link' onClick={e => clickIndustry('snack')}>零食</a>
        <a className='link space' onClick={e => clickIndustry('phone')}>手机</a>
        <a className='link space' onClick={e => clickIndustry('airport')}>机场</a>
        <a className='link space' onClick={e => clickIndustry('steel')}>钢铁</a>
      </div>
    </div>
    <div className='row'>
    {loading && <div>
        <span className="spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
        <span className='ms-2'>Loading...</span>
      </div>}
      <div className='col'>
        { items.length !== 0 && 
          <ul className="dropdown-menu suggest">{items}</ul>
        }
        <div className='corps-selected'>{corp_items}</div>
      </div>
      
    </div>
  </div>
    {/* <CashFlows code='SH603027'></CashFlows> */}
    {graphsJsx}
    {/* <Trend></Trend> */}
  </div>
}