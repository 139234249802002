import { fmtYear } from "../util.tsx"
import { BarYearData, BarYearTypeProps, BarYearViz } from "./Interfaces"
import * as _ from 'lodash'

interface labelOrderMap{
    'A':{labels:string[],kinds:string[]}
    'H'?:{labels:string[],kinds:string[]}
    'M'?:{labels:string[],kinds:string[]}
}

export default function PrepareBarYearDraw(
    props: BarYearTypeProps,
    labelOrderMap: labelOrderMap,
    labelMap: {[key:string]:string},
    no_cap?:boolean) {
    const data = props.data as BarYearData
    const cap_key = 'equity' in props.data ?
        'equity' : 'cap_empl'
    const rev_key = 'rev_net' in data ?
                        'rev_net' : 'rev'
    if(no_cap)
        props.data[cap_key] = props.data[rev_key]

    const numeric_year = data['year'].replace(/\W/g, '')
    const numeric_latest_year = 
      data['latest_year'].replace(/\W/g, '')
    const labelOrder = labelOrderMap[data.market]

     // trim FY (fiscal year) for US stocks
     if(!props.latestYearMap.current.has(props.code))
     props.latestYearMap.current.set(props.code,
       _.trim(numeric_latest_year,'FY'))

    // let rev_total = 0
    // for(const [i,kind] of labelOrder.kinds.entries()){
    //     if(kind !== 'rev')
    //         break
    //     rev_total += data[labelOrder.labels[i]]
    // }
    
    
    
    const max_val = data[rev_key] > data[cap_key]? data[rev_key]: data[cap_key]
    let min_val = data['op_inc'] > 0 ? 0 : data['op_inc']
    if('insure_rev_net' in data)
        min_val = (data['insure_rev_net'] as number) < min_val ? 
        (data['insure_rev_net'] as number) : min_val
    min_val = min_val < data[cap_key] ? min_val : data[cap_key]
    if('interest_net' in data)
        min_val = (data['interest_net'] as number) < min_val ? 
        (data['interest_net'] as number) : min_val
    
    // console.log(max_val,min_val)
    const arr: BarYearViz[] = []
    let ref = 0
    const abs = (val:number) => val < 0 ? -val : val
    labelOrder.labels.forEach(
        function(fld:string,i:number){
        const kind = labelOrder.kinds[i]
        let color_kind = kind
        
        if (kind === 'cost')
            ref -= data[fld]
        if (fld === 'op_inc' || kind === 'cap' 
            || fld === 'gross_inc')
            ref = 0
        if (kind === 'inc' && data[fld] < 0 ){
            color_kind = 'cost'
            ref += data[fld]
        }
        if(kind === 'cap' && data[fld] < 0){
            ref += data[fld]
        }
        // console.log(kind,labelOrder.kinds[i-1],ref)
        const item:BarYearViz = {
            fld: fld,
            display: labelMap[fld],
            val: abs(data[fld]),
            val_actual: data[fld],
            y: ref,
            color_kind: color_kind,
            kind: kind
        }
        if(fld === 'op_inc')
            item.rev_ratio = data['op_inc']/data[rev_key]
        if(fld === 'op_inc' &&
            labelOrder.labels.includes('gross_inc'))
            item.gross_ratio = data['op_inc']/data['gross_inc']
        if(fld === 'gross_inc')
            item.rev_ratio = data['gross_inc']/data['rev']
        //     item.rev_ratio = data['gro']/data['rev']
        // console.log(item.y)
        arr.push(item)
        
        if(color_kind === 'inc')
            ref += data[fld]
    })

    // console.log(arr)
    arr.forEach(x=>{
        x.y = max_val - x.y - x.val
    })
    
    const arr2 = no_cap ?
        arr.filter(x=>x.fld!==cap_key) :
        arr
    // console.log(data)
    const title = `${data.name} ${fmtYear(data.year_quarter)}`
    // const margin = ({h:50,v:20})
    // const [width, height] = [360, 300]
    return [arr2, data[cap_key], cap_key,
     title, max_val, min_val]

}