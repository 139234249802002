import { MultiSearchResult } from "meilisearch/dist/types/types";
import * as _ from 'lodash'
import { BarYearProps, TokenChartProps } from "./Interfaces";
import { useEffect, useState, useRef } from 'react';
import {url} from '../Asset.js'

interface SuggAllocation{
    A_stocks: number,
    H_stocks: number,
    M_stocks: number
}

const defaultAllocationInit = {
    'A_stocks': 5,
    'H_stocks': 3,
    'M_stocks': 2
}

function allocateSugg(
    res: MultiSearchResult<Record<string, any>>[],
    defaultAllocation: SuggAllocation=defaultAllocationInit,
    key_order=['A_stocks','H_stocks','M_stocks']){
    const allocation = structuredClone(defaultAllocation)
    const res2 = _.sortBy(res,x=>
        x.hits.length-allocation[x.indexUid])
    const rec:string[] = []
    res2.every((x,i)=>{
        let diff = allocation[x.indexUid]-x.hits.length
        if(diff <= 0)
            return false
        else{
            rec.push(x.indexUid)
            allocation[x.indexUid] = x.hits.length
            const remain = key_order
                .filter(k=>!rec.includes(k))
            remain.forEach(k=>{
                const ct = Math.ceil(diff/remain.length)
                allocation[k] += ct
                diff -= ct
            })
            return true
        }
    })
    return allocation
}

function downloadSVGs(){
    const svgs = document.getElementsByTagName('svg')
    _.range(0,svgs.length).forEach(i=>{
        const svg = svgs[i]
        downloadSVG(svg,svg.id)
    })
}

function downloadSVG(svg:SVGSVGElement,fname:string){
    // const svg = document.getElementById(id).getElementsByTagName('svg')[0]
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    const svg_html = svg.outerHTML;

    const blob = new Blob([svg_html.toString()]);
    const element = document.createElement("a");
    element.download = `${fname}.svg`;
    element.href = window.URL.createObjectURL(blob);
    element.click();
    element.remove();
}

async function fetchData(props:TokenChartProps,
    curl:string) {
    // console.log('fetch',props)

    try{
        const response = await fetch(curl,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          }
        })
        const result = await response.json()
        if(response.status === 200){
           return result
        }else{
          console.log(result['detail'])
          props.clearToken()
          if(Object.hasOwn(props,'rmSelf'))
            props.rmSelf!()
          return undefined
        }
      }catch(e){
        console.error(e)
        return undefined
      }
}


function useTokenFetch<T>(props:TokenChartProps,endpoint:string){
    const [data,setData] = useState<T>()

    useEffect(()=>{
        // const code = 'SH600519'
        // const year = 'latest'
        const code = props.code
        const curl = `${url}/${endpoint}?code=${code}`

        // console.log(props)
        fetchData(props,curl).then(result=>{
            if(result && result.length ==  0 && props.rmSelf)
                props.rmSelf()
            else
                setData(result)
            })
        // fetch(curl).then(res=>{
        //     res.json().then(resData=>setData(resData))
        //     // setData(res.json() as BarYearData)
        // })
        return function(){setData(undefined)}
    },[])

    return data
}

const fmtYear = (year:number)=>{
    const year_int = Math.floor(year)
    const q = year - year_int
    const year_str = year.toString()
    return q < .4 ? year_str.replace('.','q') : year_int.toString()
}


export {allocateSugg,downloadSVGs,fetchData,
    useTokenFetch,fmtYear}