import React from 'react';
import * as d3 from "d3";
import { nanoid } from 'nanoid';
import { useEffect, useState, useRef } from 'react';
import * as _ from 'lodash'


interface GlobalParams{
    width: number,
    height: number,
    margin: {
        top:number,
        bottom:number,
        left:number,
        right: number
    }
}

type Scales = d3.ScalePoint<string>|
    d3.ScaleLinear<number, number, never> |
    d3.ScaleBand<string> 

interface AxisProps{
    orient: 'left' | 'right' | 'bottom',
    params: GlobalParams,
    start?: number,
    end?: number,
    labels?: string[],
    tickFormat?: (d:number|string,i?:number) => number|string,
    grid?: boolean,
    scaleFun?: (domain:(string|number)[],rg:number[]) 
        => Scales
    // padding?: number,
    // x?:number,
    setScale: (scale:Scales) =>void
    textStyleFun?: (texts:d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>)=>void
}

const getPlotHeight = (params:GlobalParams)=>
    params.height*(1-params.margin.top
        -params.margin.bottom)
const getPlotWidth = (params:GlobalParams)=>
    params.width*(1-params.margin.left
        -params.margin.right)
const getMinLen = (params:GlobalParams)=>{
    const width = getPlotWidth(params)
    const height = getPlotHeight(params)
    return d3.min([width,height])
}

function Axis(props:AxisProps){
    // id cannot start with number!!!
    const id = 'x'+nanoid()
    const params = props.params
    let scale:Scales
    
    // console.log('Axis',props)
    const rg = props.orient !== 'bottom' ? 
            [getPlotHeight(props.params),0] :
            [0, getPlotWidth(props.params)]
    if(props.end){
        const start = props.start ? props.start : 0
        scale = d3.scaleLinear()
            .domain([start,props.end])
            .range(rg)
            
    }else if(props.labels){
        if(props.scaleFun)
            scale = props.scaleFun(props.labels,rg)
        else
            scale = d3.scalePoint()
                .domain(props.labels)
                .rangeRound(rg)
                .padding(0.5)
    }else{
        throw Error('One of "end" or "labels" props must exists!')
    }

    useEffect(()=>{
        const g = d3.select(`#${id}`)
        // console.log('g',g)

        const x = props.orient === 'right' ? 
            (1-params.margin.right)*params.width :
            params.margin.left*params.width
        const y = props.orient !== 'bottom' ? 
            params.margin.top*params.height:
            (1-params.margin.bottom)*params.height
        
        const axisMap = {
            'left': d3.axisLeft,
            'right': d3.axisRight,
            'bottom': d3.axisBottom
        }
        const axisFun = axisMap[props.orient]
        if(_.isUndefined(props.tickFormat))
            g.attr('transform',
                `translate(${x},${y})`)
            .call(axisFun(scale as any)
                .tickSizeOuter(0) as any)
        else
            g.attr('transform',
                `translate(${x},${y})`)
            .call(axisFun(scale as any)
                .tickFormat(props.tickFormat as any)
                .tickSizeOuter(0) as any)

        const grid_len = props.orient === 'bottom'?
            getPlotHeight(params):
            getPlotWidth(params) 
            
        const axisGrid = axisFun(scale as any)
            .tickSize(-grid_len)
            .tickSizeOuter(0)
            .tickFormat((d,i)=>'')
        
    //     console.log(props.grid,rg,scale.domain(),
    // scale.range())
        if(props.grid)
            g.append("g")
            .attr('class','grid')
            .call(axisGrid as any);
        //  console.log(scale)

        if(props.textStyleFun){
            const texts = g.selectAll('text')
            props.textStyleFun(texts)
        }
            

        props.setScale(scale)

        // the following clean-up function do not work with routers
        // return ()=>{g.remove()}

        
    },[])

    return <g id={id}></g>
}

export {GlobalParams, Scales,Axis,getMinLen}