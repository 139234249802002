import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Search from './routes/Search.tsx';
import Company from './routes/Company.tsx';
import {
  // HashRouter,
  createBrowserRouter,
  // createHashRouter,
  RouterProvider,
  // BrowserRouter,
  // Routes,
  // Route,
  // Outlet
} from "react-router-dom";

import Root from './routes/Root.tsx'

import "./bootstrap.min.css"
import './index.css';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path:'/',
        element: <Search/>
      },
      {
        path:'/company/:code',
        element: <Company/>
      }
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);





// function App(){
//   const [open, setOpen] = React.useState(false);
//   const [token, setToken] = useState('')
//   const usernameRef = useRef<string>('');

//   const openLogin = () => 
//     setOpen(true)

//   const closeLogin = () => 
//     setOpen(false)

//   const openUserPanel = ()=>{}

//   const clearToken = () => {
//     setToken('')
//     localStorage.removeItem("token")
//     localStorage.removeItem("username")
//     usernameRef.current = ''
//   }

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       const username = localStorage.getItem("username");
//       if (username) {
//         usernameRef.current = username
//         setToken(token)
//       }
//     }
//   }, [])
  
//   return <div className='content'>
//     <div className='header ps-3 pb-3 pt-3 mb-2 border-bottom'>
//       <span>Opture</span>
//       <span className='ms-2'>运营结构分析</span>
//       <span className='login me-5'>
//          {!token &&  <a onClick={openLogin}>Login</a>}
//          {token && <a onClick={openUserPanel}>{usernameRef.current}</a>}
//       </span>
//     </div>
//     {/* <Search token={token} clearToken={clearToken}/> */}
//     { token &&
//     <Company token={token} code={'SH600519'} clearToken={clearToken}/>
//     }
//     {/* <BarYear code='SZ002568' /> */}
//     <Dialog
//         open={open}
//         onClose={closeLogin}
//         PaperProps={{
//           component: 'form',
//           onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
//             event.preventDefault();
//             const formData = new FormData(event.currentTarget);
//             try {
//               const tokenUrl = url + '/token'
//               const response = await fetch(tokenUrl, {
//                 method: "POST",
//                 body: formData,
//               });
//               console.log(response.status)
//               const result = await response.json();
//               if (response.status === 200) {
//                 const formJson = Object.fromEntries((formData as any).entries());
//                 usernameRef.current = formJson.username
//                 setToken(result.access_token) 
//                 localStorage.setItem("username", formJson.username);
//                 localStorage.setItem("token", result.access_token);
//                 // localStorage.setItem("refresh_token", result.refresh_token);
//                 console.log(result)
//                 setOpen(false)
//                 // setName("");
//                 // setEmail("");
//                 // setMessage("User created successfully");
//               } else {
//                 console.log(result)

//                 // setMessage("Some error occured");
//               }
//               // console.log("Success:", result);
//             } catch (error) {
//               console.error("Error:", error);
//             }
//             // const email = formJson.email;
//             // console.log(formData,formJson);
//             // closeLogin();
//           },
//         }}
//       >
//         <DialogTitle>Login</DialogTitle>
//         <DialogContent>
//           {/* <DialogContentText>
//             To subscribe to this website, please enter your email address here. We
//             will send updates occasionally.
//           </DialogContentText> */}
//           <TextField
//             autoFocus
//             required
//             margin="dense"
//             id="username"
//             name="username"
//             label="User Name"
//             type="text"
//             fullWidth
//             variant="standard"
//           />
//           <TextField
//             autoFocus
//             required
//             margin="dense"
//             id="password"
//             name="password"
//             label="Password"
//             type="password"
//             fullWidth
//             variant="standard"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeLogin}>Cancel</Button>
//           <Button type="submit">Login</Button>
//         </DialogActions>
//       </Dialog>
// </div>
// }



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
