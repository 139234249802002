
import * as d3 from "d3";
import {BarYearViz} from "./Interfaces";
import * as _ from 'lodash'

interface Margin{
    h:number,
    v:number
}

export default function DrawBarYear(
    arr:BarYearViz[],
    cap:number,
    cap_name:string,
    title: string,
    max_val:number,
    min_val:number,
    sid:string,
    width:number,
    height:number,
    margin:Margin,
    no_cap?:boolean
    ) {

    const y = d3.scaleLinear()
    .domain([0,max_val-min_val])
    .range([0, height])
  
    const yr = d3.scaleLinear()
            .domain([min_val, max_val])
            .range([height,0])
    
    const x = d3.scaleBand()
            .domain(arr.map(x => x.fld))
            .rangeRound([0, width])
            .padding(0.5)
    
    const c = d3.scaleOrdinal(['inc','cost','cap'],
                ['tomato','seagreen','orange']);
    
    const yAxisGrid = d3.axisLeft(yr)
        .tickSize(-width)
        .tickSizeOuter(0)
        .tickFormat((d,i)=>'')
    const yAxis = d3.axisLeft(yr)
        .tickSizeOuter(0)
        // .tickSizeInner(0)
    const yAxisFunc = (yAxis) => g => g.call(yAxis)
    // .attr("transform", `translate(${margin.left},0)`)
    // .call(g => g.select(".domain").remove()) 
    
    const xAxisGrid = d3.axisBottom(x)
        .tickSize(-height)
        .tickSizeOuter(0)
        .tickFormat((d,i)=>'')

    const xAxis = d3.axisBottom(x)
        .tickSizeOuter(0)
        .tickFormat((d,i)=>arr[i].display)
    const xAxisFunc = (xAxis)=> g => g
        .attr("transform", `translate(0,${height})`)
        .call(xAxis)
    
    

    // xAxis.tickFormat((d,i)=>arr[i].display)
    var tooltip = d3.select("body").append("div")   
        .attr("class", "tooltipx tooltip")               
        .style("opacity", 0);

// // hover events
// .on('mouseover', (d, i) => {  tooltip.transition().duration(200).style("opacity", .9);      
//   tooltip.html(`${textValue(d)} \n$${yValue(d)} Billion`).style("left", (d3.event.pageX) + "px").style("top", (d3.event.pageY) + "px");
// })
// .on('mouseout', d => {
//   tooltip.transition() .duration(500) .style("opacity", 0);
// });

    const svg = d3.select('#'+sid);
    const gx = svg.attr('width',width+margin.h*2)
                .attr('height',height+margin.v*2)
        // .attr("viewBox", [0, 0, width+margin.h*2, height+margin.v*2])
                .append('g')
                .attr('transform',`translate(${margin.h},${margin.v})`);
    
    // const xAxisGrid = xAxis.ticks(5)
    // .tickSize(-width)
    // // .tickFormat()
    // .orient("top");
    // // const xAxisGrid = xAxis.ticks()
    // g.append("g")
    //     .classed('x', true)
    //     .classed('grid', true)
    //     .call(xAxis);
    
    // gx.append("g")
    // .attr('class','grid')
    // .call(xAxisFunc(xAxisGrid));

    gx.append("g")
    .attr('class','grid')
    .call(yAxisFunc(yAxisGrid));

    gx.append("g")
    .call(xAxisFunc(xAxis));
    gx.append("g")
    .call(yAxisFunc(yAxis));

    const g = gx.append('g')

    const gs = g.selectAll('g')
        .data(arr)
        .join('g')
    
    const gr = gs.append('g') // g for rects and their titles
    
   

    gr.append('rect')
        .attr('fill',d => c(d.color_kind))
        .attr('x', d => x(d.fld) as number)
        .attr('y', d => y(d.y))
        .attr('height',d => y(d.val))
        .attr('width',x.bandwidth())
        .on('mouseover', (e,d)=>{
            // console.log(e.pageX,e.pageY)
            tooltip.style('display', 'block')
            tooltip.transition()
            .duration(100).style("opacity", .9);      
            tooltip.html(`${d.val_actual.toFixed(2)}`)
                .style("left", (e.pageX+30) + "px")
                .style("top", (e.pageY-15) + "px");
        })
        .on('mouseout',(e,d)=>{
            tooltip.transition()
                .duration(100)
                .style("opacity", 0)
                .on('end', ()=>{
                     tooltip.style('display', 'none')});
        })
    
    
    // gr.append('title')
    //     .text(d => {
    //         return d.val_actual.toFixed(2)
    //     })

    const get_percent = (val_input:number)=>{
        const val = Math.round(val_input * 100)
        if(_.isNaN(val) || !_.isFinite(val))
            return ''
        else
            return val + '%'
    }
    
    const font_size = 13
    const gt = gs.append('g') // g for text
    gt.append('text')
        .attr('fill','black')
        .attr('x', d => x(d.fld) as number)
        .attr('y', (d,i) => i > 2 ? y(d.y)-22 : y(d.y)-6)
        .text(d => {
            if(['gross_inc','op_inc'].includes(d.fld)){
            return get_percent(d.rev_ratio!)
            }else{
            return ''
            }
        })
        .style('font-size',font_size)
    
    gt.filter(d=>['gross_inc','op_inc'].includes(d.fld))
        .append('title')
        .text(d => {
            if(d.fld === 'gross_inc') return 'gross margin'
            else return 'operating margin'
        })
    
    const op_inc_g = gs.filter(d => d.fld === 'op_inc')
    
    const g_gross = op_inc_g.append('g')
    g_gross.append('text')
        .attr('fill','black')
        .attr('x', d => x(d.fld) as number)
        .attr('y', d => y(d.y)-5)
        .text(d => {
            return get_percent(d.gross_ratio!)
        })
        .style('font-size',font_size)
    g_gross.append('title')
        .text('op_inc/gross_inc')
    
    // console.log(no_cap, !no_cap)
    if(!no_cap){
        const g_cape = op_inc_g.append('g')
        g_cape.append('text')
            .attr('fill','black')
            .attr('x', d => x(d.fld) as number)
            .attr('y', d => y(d.y)-65)
            // .attr('y', d => y(max_val-data['cap_empl'])+20)
            .text(d => {
                return get_percent(d.val_actual/cap)
            })
            .style('font-size',font_size)
        g_cape.append('title')
            .text(`op_inc/${cap_name}`)
    }
        

    
   

    // add gridlines
   
    
    g.append('text')
        .attr('fill','black')
        .attr('x',width*0.5)
        .attr('y',-6.5)
        .attr('text-anchor','middle')
        .text(title)
        // 
    g.append('text')
        .attr('fill','black')
        .attr('x',width*(-0.05))
        .attr('y',-7)
        .attr('font-size','.7em')
        .attr('text-anchor','middle')
        .text('亿元')
        // .text(`${data['name']} ${numeric_year}`)

    // if(min_val < 0){
    //     g.append('line')
    //     .style('stroke-width',1)
    //     .style('stroke','grey')
    //     .style('stroke-dasharray','3,3')
    //     .attr('y1',y(max_val))
    //     .attr('y2',y(max_val))
    //     .attr('x1',0)
    //     .attr('x2',width)
    // }

    return [g,tooltip]
}