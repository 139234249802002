import { TokenChartProps } from "./Interfaces"
import { useEffect, useState, useRef } from 'react';
import { GlobalParams, Scales, Axis } from './Elems.tsx'
import React from 'react';
import {fmtYear, useTokenFetch } from './util.tsx';
import * as _ from 'lodash'
import * as d3 from "d3";

interface BalDatum{
    'asset':number,
    'liability':number,
    // 'equity':number,
    'year':number,
    'name':string
}

type BalData = BalDatum[]

interface BalanceChartProps{
    data: BalData
}

interface BarDatum{
    x:number,
    y:number,
    height:number,
    orient:-1|0 // left | right,
    cidx: 0|1|2 // color idx
}

interface VizData{
    min: number;
    max: number;
    bars: BarDatum[];
    // tickMap: {
    //     [key: string]: string;
    // };
}

function getVizData(data:BalData):VizData{
    const bars:BarDatum[] = []
    for(var item of data){
        bars.push({
            x:item.year,
            y:item.asset,
            height: item.asset,
            orient:-1,
            cidx: 0
        })
        bars.push({
            x:item.year,
            y:item.asset,
            height: item.liability,
            orient:0,
            cidx: 1
        })
        if(item.asset > item.liability)
            bars.push({
                x:item.year,
                y:item.asset-item.liability,
                height:item.asset-item.liability,
                orient:0,
                cidx: 2
            })
        else
            bars.push({
                x:item.year,
                y:0,
                height:-(item.asset-item.liability),
                orient:-1,
                cidx: 2
            })
    }
    const max = _.max(data.map(x=>x.asset))
    const min = _.min(data.map(function(x){
        const diff = x.asset - x.liability
        return diff > 0 ? 0 : diff
    }))

    return {min:min!,max:max!,bars:bars}
}

interface BalBarsProps{
    data: VizData,
    xScale: Scales,
    yScale: Scales,
    params: GlobalParams,
    colors: string[]
}

function BalBars(props:BalBarsProps){
    const params = props.params
    const gx = params.margin.left * 
        params.width
    const gy = params.margin.top *
        params.height
    const width = 15 // bar width
    
    const rg = props.yScale.range()
    const ys = d3.scaleLinear()
       .domain(props.yScale.domain() as any)
       .range([rg[1],rg[0]])
    
    // console.log(props.yScale.domain(),rg)

    const domain_size = props.data.max - props.data.min
    const sizeScale = d3.scaleLinear()
        .domain([0,domain_size])
        .range([rg[1],rg[0]])
    
    const bars = props.data.bars.map((d,i)=>{
        const x = props.xScale(fmtYear(d.x) as any)
        const y = ys(props.data.max-d.y+props.data.min)
        const height = sizeScale(d.height)
        const color = props.colors[d.cidx]
        return <rect key={i}
            x={x!+width*d.orient}
            y={y}
            width={width}
            height={height}
            fill={color}></rect>
    })
    return <g transform={`translate(${gx},${gy})`}>
        {bars}
    </g>
}


function BalanceChart(props:BalanceChartProps){
    const vizData = getVizData(props.data)
    // console.log('vizData',vizData)
    const params: GlobalParams = {
        width: 600,
        height: 340,
        // margins are defined proportional to width and height
        margin:{
            top: 0.1,
            bottom: 0.07,
            left: .07,
            right: 0.1
        }
    }

    const [leftScale, setLeftScale] = useState<Scales>()
    const [bottomScale, setBottomScale] = useState<Scales>()

    const xScaleFun = (d:(string|number)[],r:number[])=>
        d3.scaleOrdinal()
           .domain(d as string[])
           .range(r) as d3.ScaleOrdinal<string,number,never>
    // useEffect(()=>{
    //     const code = props.code
    //     const curl = `${url}/cash_flows?code=${code}`

    //     fetchData(props,curl).then(result=>setData(result))
    //     return function(){setData(undefined)}
    // },[])

    return <div>
    {vizData && 
    <svg width={params.width} height={params.height}>
        <text textAnchor='middle' x={params.width*.5} y={params.height*.05}>{props.data[0].name}</text>
        <Axis params={params}
            orient='left'
            grid
            start={vizData.min}
            end={vizData.max}
            setScale={scale=>{
                //cannot use setLeftScale(scale) here. See:
                //https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
                setLeftScale(()=>scale)}}></Axis>

    
        <Axis params={params}
            orient='bottom'
            labels={props.data.map(d=>fmtYear(d.year)) as string[]}
            setScale={scale=>{setBottomScale(()=>scale)}}></Axis>
        
        {leftScale && bottomScale && 
            <BalBars params={params} 
                xScale={bottomScale}
                yScale={leftScale}
                data={vizData}
                // '#40E0D0'
                colors={['tomato','seagreen','#40E0D0']} />
        }
      
        </svg>}
    </div>
}

export default function Balance(props:TokenChartProps){
    const data = useTokenFetch<BalData>(props,'bal')
    // console.log('bal',data)

    return <>
        {data && <BalanceChart data={data}/>}
    </>
}
