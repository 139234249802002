// const url = 'http://localhost:3000'
// const url = 'http://localhost:8000/api'
// const url = 'http://1.15.235.222:8080'
// const url = window.location.origin+'/api'

const urlMap = {
    'development':'http://localhost:8000/api',
    'production':window.location.origin+'/api'
}
const url = urlMap[process.env.NODE_ENV]

const meiliMap = {
    'development':{
        host: 'http://localhost:7700/',
        apiKey: 'aSampleMasterKey'
    },
    'production':{
        host: 'http://47.105.59.149:7700',
        apiKey: '90cf7a348837345573b3e0206c3a2ebc509936536d70a87e718e962e4a6556af'
  }
}
// const meiliConfig = meiliMap[process.env.NODE_ENV]

const meiliConfig = meiliMap['production']

// console.log('node_env',process.env.NODE_ENV)

export {url, meiliConfig}