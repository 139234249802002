import { TokenChartProps } from "./Interfaces.tsx"
import { useEffect, useState, useRef } from 'react';
import { GlobalParams, Scales, Axis } from './Elems.tsx'
import React from 'react';
import {fmtYear, useTokenFetch } from './util.tsx';
import * as _ from 'lodash'
import * as d3 from "d3";

interface HeadCountEachYear{
    'year':number
    'count':number
}

type HeadCountData = HeadCountEachYear[]

interface HeadCountChartProps{
    data: HeadCountData
}

interface BarDatum{
    x:number,
    y:number,
    height:number,
    orient:-1|0 // left | right,
    cidx: 0|1|2 // color idx
}

interface VizData{
    min: number;
    max: number;
    bars: HeadCountData;
    // tickMap: {
    //     [key: string]: string;
    // };
}

function getVizData(data:HeadCountData):VizData{
    return {min:0,
        max:_.max(data.map(x=>x.count))!,
        bars:data!}
}

interface HeadCountBarsProps{
    data: VizData,
    xScale: Scales,
    yScale: Scales,
    params: GlobalParams,
    colors: string[]
}

function HeadCountBars(props:HeadCountBarsProps){
    const params = props.params
    const gx = params.margin.left * 
        params.width
    const gy = params.margin.top *
        params.height
    // const width = 15 // bar width
    
    const rg = props.yScale.range()
    const ys = d3.scaleLinear()
       .domain(props.yScale.domain() as any)
       .range([rg[1],rg[0]])
    
    // console.log(props.yScale.domain(),rg)

    const domain_size = props.data.max - props.data.min
    const sizeScale = d3.scaleLinear()
        .domain([0,domain_size])
        .range([rg[1],rg[0]])
    
    console.log(props.xScale.domain(),
        props.xScale.range()
    )
    const bars = props.data.bars.map((d,i)=>{
        const x = props.xScale(fmtYear(d.year) as any)
        const y = ys(props.data.max-d.count+props.data.min)
        const height = sizeScale(d.count)
        const color = props.colors[0]
        return <rect key={i}
            x={x}
            y={y}
            width={(props.xScale as d3.ScaleBand<string>).bandwidth()}
            height={height}
            fill={color}></rect>
    })
    return <g transform={`translate(${gx},${gy})`}>
        {bars}
    </g>
}


function HeadCountChart(props:HeadCountChartProps){
    const vizData = getVizData(props.data)
    console.log('vizData',vizData)
    const max_val = _.max(props.data.map(x=>x.count))
    const width = 600*props.data.length/6
    const min_left_margin = max_val! > 1e5 ? 50 : 39
    const params: GlobalParams = {
        width: width,
        height: 340,
        // margins are defined proportional to width and height
        margin:{
            top: 0.1,
            bottom: 0.07,
            left: .07*width < min_left_margin ? min_left_margin/width: .07,
            right: 0.1
        }
    }

    const [leftScale, setLeftScale] = useState<Scales>()
    const [bottomScale, setBottomScale] = useState<Scales>()

    const xScaleFun = (d:(string|number)[],r:number[])=>
        d3.scaleBand()
           .domain(d as string[])
           .range(r)
           .padding(.7)
    // useEffect(()=>{
    //     const code = props.code
    //     const curl = `${url}/cash_flows?code=${code}`

    //     fetchData(props,curl).then(result=>setData(result))
    //     return function(){setData(undefined)}
    // },[])

    return <div>
    {vizData && 
    <svg width={params.width} height={params.height}>
        <text textAnchor='middle' x={params.width*.5} y={params.height*.05}>{'员工人数'}</text>
        <Axis params={params}
            orient='left'
            grid
            start={vizData.min}
            end={vizData.max}
            setScale={scale=>{
                //cannot use setLeftScale(scale) here. See:
                //https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
                setLeftScale(()=>scale)}}></Axis>

    
        <Axis params={params}
            orient='bottom'
            scaleFun={xScaleFun}
            labels={props.data.map(d=>fmtYear(d.year)) as string[]}
            setScale={scale=>{setBottomScale(()=>scale)}}></Axis>
        
        {leftScale && bottomScale && 
            <HeadCountBars params={params} 
                xScale={bottomScale}
                yScale={leftScale}
                data={vizData}
                // '#40E0D0'
                colors={['skyblue']} />
        }
      
        </svg>}
    </div>
}

export default function HeadCount(props:TokenChartProps){
    const data = useTokenFetch<HeadCountData>(props,'head_count')
    console.log('head_count',data)
    return <>
        {data && <HeadCountChart data={data}/>}
    </>
}
