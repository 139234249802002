import React, { useEffect, useState, useRef } from 'react';
// import BarYear from '../comps/BarYear/BarYear.tsx';
import * as _ from 'lodash'
import Trend from '../charts/Trend.tsx'
import CashFlows from '../charts/CashFlows.tsx';
import { BarYearData } from '../charts/BarYear/Interfaces.tsx';
import BarYearType from '../charts/BarYear/BarYearType.tsx'
// import { fetchData } from '../comps/util.tsx';
import {url} from '../Asset.js'
import BarYear from '../charts/BarYear/BarYear.tsx'

import { useOutletContext, useParams } from 'react-router-dom';
import Balance from '../charts/Balance.tsx';
import HeadCount from '../charts/HeadCount.tsx';


interface CompanyProps{
    code: string,
    token: string,
    clearToken: ()=>void
}

interface Latest{
    incs: number,
    bals: number,
    cfs: number
}

// interface BarYearType{
//     type: 'barYear',
//     year: string
// }

type GraphDatum = 'trend'|'bal'|'cashFlows'|'barYear'|'headCount'
type GraphData = GraphDatum[]

export default function Company(propsx:{}){
    const [token, clearToken]: [string,()=>void] = useOutletContext();
    const {code} = useParams()
    const props: CompanyProps = {
        code: code!,
        token: token,
        clearToken: clearToken
    }
    const [latest,setLatest] = useState<Latest>()
    // console.log(token)
    const [barYearDataArr, setBarYearDataArr] = useState<BarYearData[]>([])
    const latestYearMap = useRef<Map<string,string>>(new Map())

    const [graphData, setGraphData] = useState<GraphData>(['bal','cashFlows','headCount','trend','barYear',
        'barYear','barYear','barYear','barYear'
    ])
    
    // const graphData:  GraphData = []

    // const graphData:GraphData = ['trend','cashFlows']
    const rmChart = (tag:GraphDatum)=> setGraphData(graphData.filter(x=>x!==tag))

    const chooseGraph = (item: GraphDatum, data?:BarYearData)=>{
        if(item == "trend")
            return <Trend code={props.code}></Trend>
        else if(item == "bal")
            return <Balance code={props.code} token={props.token} 
        clearToken={props.clearToken}></Balance>
        else if(item == 'cashFlows')
            return <CashFlows code={props.code} token={props.token} 
                clearToken={props.clearToken}></CashFlows>
        else if(item == 'headCount')
            return <HeadCount code={props.code} token={props.token}
                clearToken={props.clearToken}
                rmSelf={()=>rmChart('headCount')} />
        else
            return <BarYearType data={data!} code={props.code}
                latestYearMap={latestYearMap} year={data!.year}/>
    }

    var barYearIdx = 0
    const graphJsx: React.JSX.Element[] = []
    for(var item of graphData){
        // console.log(item,props.token)
        if(item=='barYear'){
            if(barYearDataArr.length > 0 && barYearIdx < barYearDataArr.length){
                graphJsx.push(<div className='Chart' key={`${item}_${barYearIdx}`}>{chooseGraph(item,barYearDataArr[barYearIdx])}</div>)
                barYearIdx += 1
            }
        }else
            graphJsx.push(<div className='Chart' key={`${item}_${barYearIdx}`}>{chooseGraph(item,barYearDataArr[barYearIdx])}</div>)
    }

    const show_qt_cfs = latest && latest.cfs.toString().slice(-1)!=='4'
    const show_qt_op = latest && latest.incs.toString().slice(-1)!=='4' 
                    && latest.bals.toString().slice(-1)!=='4'
    let op_yq:number|undefined = undefined
    if(latest)
        op_yq = latest.incs > latest.bals ? latest.bals : latest.incs

    const show_qt_op_no_cap = show_qt_op && latest.incs > latest.bals
    
    const [disableButton, setDisableButton] = useState(false)

    const onClickUpdate = (e)=>{
        setDisableButton(true);

        (async ()=>{
            const curl = `${url}/update?code=${props.code}`
            const response = await fetch(curl,{
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token
              }
            })
            setDisableButton(false)
            // const result = await response.json()
          })()
    }


    useEffect(() => {
        if(token)
        (async ()=>{
          const curl = `${url}/op_many?code=${props.code}`
          const response = await fetch(curl,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + props.token
            }
          })
          const result = await response.json()
          setBarYearDataArr(result as BarYearData[])
        })()
        return function(){setBarYearDataArr([])}
    }, [token])


    useEffect(() => {
        if(token)
        (async ()=>{
          const curl = `${url}/latest?code=${props.code}`
          const response = await fetch(curl,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + props.token
            }
          })
          const result = await response.json()
          setLatest(result as Latest)
        })()
        return function(){setBarYearDataArr([])}
    }, [token])



    return <div>
        <div className="conainter">
            <button onClick={onClickUpdate} disabled={disableButton}>update</button>
        </div>
        <div>{token && graphJsx}</div>
        {(show_qt_cfs || show_qt_op ) &&<div>


            {show_qt_cfs && [<div className='Chart' key='0'>
                    <CashFlows code={props.code} 
                        token={props.token} 
                        clearToken={props.clearToken} 
                        year_quarter={latest.cfs}
                        width={330}
                        margin_left={.15}/>
                </div>,
                <div className='Chart' key='1'>
                <CashFlows code={props.code} 
                    token={props.token} 
                    clearToken={props.clearToken} 
                    year_quarter={latest.cfs-1}
                    width={330}
                    margin_left={.15}/>
            </div>]}

            {show_qt_op && [<div className='Chart' key='2'>
                   <BarYear code={props.code}
                    year={op_yq!.toString()} 
                    lastestYearMap={latestYearMap} 
                    token={props.token}
                    clearToken={props.clearToken} />
                </div>,
                <div className='Chart' key='3'>
                <BarYear code={props.code}
                    year={(op_yq!-1).toString()} 
                    lastestYearMap={latestYearMap} 
                    token={props.token}
                    clearToken={props.clearToken} />
            </div>
        ]}

        {show_qt_op_no_cap && [<div className='Chart' key='5'>
                   <BarYear code={props.code}
                    year={latest.incs.toString()} 
                    lastestYearMap={latestYearMap} 
                    no_cap={true}
                    token={props.token}
                    clearToken={props.clearToken} />
                </div>,
                <div className='Chart' key='6'>
                <BarYear code={props.code}
                    year={(latest.incs-1).toString()} 
                    lastestYearMap={latestYearMap} 
                    no_cap={true}
                    token={props.token}
                    clearToken={props.clearToken} />
            </div>
        ]}

        </div>}
        
    </div>
}