import * as _ from 'lodash'


const labelOrderMap = {
    default:{
        'A':{labels:[
                'rev','cogs','gross_inc',
                'fu','admin','sale','rd','op_inc',
                'cap_empl'
            ],kinds:[
                'inc','cost','inc',
                'cost','cost','cost','cost','inc',
                'cap'
            ]},
        'H':{labels:[
            'rev','cogs','gross_inc','sale',
            'admin','other','rd','op_inc',
            'cap_empl'
        ], kinds:[
            'inc','cost','inc','cost',
            'cost','cost','cost','inc',
            'cap'
        ]},
        'M':{labels:['rev','cogs','gross_inc',
            'sale_admin','rd','op_inc','cap_empl'],
            kinds:['inc','cost','inc',
                'cost','cost','inc','cap']}
    },
    'bank':{
        'A':{
        labels:['interest_net',
        'commission_net','invest_income',
        'rev_other','fu','admin','cost_other',
        'op_inc','equity'],
        kinds:['inc','inc','inc','inc',
            'cost','cost','cost','inc',
            'cap']
        }
    },
    // 'bankNoFloatBar':{
    //     'A':{
    //     labels:['interest_net',
    //     'commission_net','invest_income',
    //     'rev_other','fu','admin','cost_other',
    //     'op_inc','equity'],
    //     kinds:['inc','inc','inc','inc',
    //         'cost','cost','cost','inc',
    //         'cap']
    //     }
    // },
    'insurance':{
        'A':{
        labels:['insure_rev_net',
            'invest_income', 
            'rev_other', 
             'fu', 
            'commission', 'admin', 
            'cost_other', 'op_inc',
            'equity'],
        kinds:['inc','inc','inc',
            'cost','cost','cost','cost',
            'inc','cap']
        }
    },
    'insurance_v2':{
        'A':{
        labels:['insure_rev_net',
            'invest_income', 
            'capital_gain',
            'rev_other', 
             'fu', 
            'commission', 'admin', 
            'cost_other', 'op_inc',
            'equity'],
        kinds:['inc','inc','inc','inc',
            'cost','cost','cost','cost',
            'inc','cap']
        }
    },
    // new insurance accounting rules applied from 2023.
    'insurance2023':{
        'A':{
        labels:['insure_rev',
            'interest_rev',
            'invest_income', 
            'rev_other', 
            'insure_cost',
            'interest_cost',
            'fu', 
            'admin', 
            'cost_other', 
            'op_inc',
            'equity'],
        kinds:['inc','inc','inc', 'inc',
            'cost','cost','cost','cost','cost',
            'inc','cap']
        }
    },
    'commodity':{
        'A':{
        labels:['interest_net',
        'commission_net','invest_income',
        'trade_net','rev_other',
        'fu','admin','cost_other',
        'op_inc','equity'],
        kinds:['inc','inc','inc','inc','inc',
            'cost','cost','cost','inc',
            'cap']
        }
    },
    // 'H':['rev','cogs','gross_inc','sale',
    // 'admin','other','rd','op_inc','cap_empl'],
    // 'M':['rev','cogs','gross_inc','sale_admin',
    // 'rd','op_inc','cap_empl']
  }

const getLabelOrderMap = (type:string|undefined)=>{
    if(_.isUndefined(type))
        return labelOrderMap.default
    else
        return labelOrderMap[type]
}


  const labelMap =  {
    'interest_net':'利息',
    'commission_net':'佣金', 
    'invest_income':'投资',
    'rev_other':'其他',
    'fu':'税附',
    'admin':'管理',
    'cost_other':'其他',
    'op_inc':'营利',
    'equity':'权益',

    'interest_rev':'利收',
    'interest_cost':'利支',
    'insure_rev':'保收',
    'insure_cost':'保支',
    'capital_gain':'公变',

    'rev':'营收',
    'cogs':'成本',
    'gross_inc':'毛利',
    'rd':'研发',
    'sale_admin':'销管',
    'sale':'销售',
    'cap_empl':'用资',
    'other':'其他',

    'insure_rev_net':'保净收',
    'commission':'佣金',
    'trade_net':'贸易',
}

export {getLabelOrderMap, labelMap}