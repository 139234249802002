import { Corp } from "../charts/Interfaces.js"
import * as _ from 'lodash'
import { Meilisearch } from 'meilisearch';
import { meiliConfig } from '../Asset.js';

const client = new Meilisearch(meiliConfig)

const normalizeName = (x:Corp)=>{
    if(x.market === 'A' || (x.market === 'H' && x.sep)){
      x.name = _.replace(x.name,/ /g,'')
    }
  }


export {normalizeName,client}