import React from 'react';
import * as d3 from "d3";
import { useEffect, useState } from 'react';
// import url from '../Asset'
import {BarYearTypeProps} from "./Interfaces.tsx";
import * as _ from 'lodash'
import DrawBarYear from './DrawBarYear.tsx';
import PrepareBarYearDraw from './PrepareBarYearDraw.tsx';

import {getLabelOrderMap,labelMap} from './LabelMap.tsx'


export default function BarYearType(props:BarYearTypeProps){
    const sid = ('bar_' + props.code + props.year).replaceAll('.','_');
    // console.log(sid)
    console.log(props.no_cap)
    useEffect(() => {
        const labelOrderMap = 
            getLabelOrderMap(props.data.type_version?
                props.data.type_version : 
                props.data.type
            )
        
        const [arr,cap,cap_key,title,max_val,min_val] = 
            PrepareBarYearDraw(props,
                labelOrderMap,labelMap,
                props.no_cap)
        
        
        console.log(arr,cap,cap_key,title,max_val,min_val)
        // // the following line transforms the plot to a traditional bar
        // // plot without floating bars.
        // arr.forEach(d=>d.y=max_val-d.val_actual)
        // const title = `${data.name} ${numeric_year}`
        const margin = ({h:50,v:20})
        const [width, height] = [360, 300]

        // console.log(arr)
        const [g,tooltip] = DrawBarYear(arr,cap,
        cap_key,
        title,max_val,min_val,
        sid,width,height,margin,props.no_cap)

        return function(){g.remove();tooltip.remove();};

    },[])
    return <div>
        <svg id={sid}></svg>
    </div>
}